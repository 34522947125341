import { useAppSelector } from '@/hooks/redux';
import { useTheme } from '@/hooks/useTheme';
import { cn } from '@/utils/helper';

import ConnectionButton from './ConnectionButton';
import LogoutButton from './LogoutButton';
import RightSidebarSettingsList from './RightSidebarSettingsList';
import { WalletInfoAlternate } from '../../widgets/WalletInfo';

interface RightSidebarAccountSettingsProps {
  panels: {
    id: number;
    txt: string;
    value?: number | string;
    unit?: string;
    preFix?: boolean;
    postFix?: boolean;
    handler?: () => void;
  }[];
  setInnerTab: (tab: 'Account' | 'Notifications' | 'Menu' | null) => void;
}

const RightSidebarAccountSettings: React.FC<RightSidebarAccountSettingsProps> = ({ panels, setInnerTab }) => {
  const { mode } = useTheme();
  const { topBanner } = useAppSelector((state) => state.modal);

  return (
    <aside className={cn('flex h-full max-lg:border-t flex-col gap-3 ss:gap-4 lg:gap-8 pt-3 ss:pt-6 lg:pt-4', topBanner && 'mt-10', mode === 'dark' ? 'border-coal-100' : '!bg-light-blue-grey')}>
      <WalletInfoAlternate />

      <RightSidebarSettingsList panels={panels} />

      <div className={cn(mode === 'dark' ? 'border-coal-100' : '!bg-light-blue-grey', 'flex flex-col gap-3 ss:gap-4 lg:gap-3 pb-4 ss:pb-6 lg:pb-0')}>
        <button
          type='button'
          onClick={() => {
            setInnerTab('Menu');
          }}
          className='text-primary dark:text-primary-blue-100 bg-primary/15 max-lg:border border-coal-100 py-3 px-6 ss:text-base/5 text-sm font-medium rounded-[4px] hover:opacity-75 active:scale-95'
        >
          Menu
        </button>

        <div className='h-[1px] lg:hidden block !border-y dark:!border-t-coal-100'></div>

        <ConnectionButton />
        <LogoutButton />
      </div>
    </aside>
  );
};

export default RightSidebarAccountSettings;
