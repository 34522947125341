import type { ClassValue } from 'clsx';

import CloseIcon from '@/assets/icons/icon-close.svg';
import WalletIcon from '@/assets/icons/icon-wallet.svg';
import { useAppDispatch } from '@/hooks/redux';
import { openWalletModal } from '@/redux/wallet/userSlice';
import { cn } from '@/utils/helper';

interface IConnectWallet {
  isModalshow?: () => void;
  children: React.ReactNode;
  className?: ClassValue;
  text?: string;
}

const ConnectWallet: React.FC<IConnectWallet> = ({ isModalshow, children, className, text }) => {
  const appDispatch = useAppDispatch();

  return (
    <div className={cn(isModalshow ? 'relative' : '', className)}>
      {isModalshow && (
        <button
          className={cn('absolute -top-[50px] right-0 bg-primary p-[14px] rounded')}
          onClick={() => {
            isModalshow();
            appDispatch(openWalletModal(false));
          }}
        >
          <CloseIcon fill='#FFFFFF' />
        </button>
      )}
      <div className='flex px-8 py-10 max-w-[765px] mx-auto justify-center lg:justify-between gap-20 lg:h-auto items-center bg-white-100 dark:bg-jet-black-100 border border-coal-50 dark:border-coal-border rounded relative'>
        <div className='flex flex-col gap-[10px]  dark:!bg-transparent max-w-[380px]'>
          <div className='flex flex-col lg:flex-row items-center gap-[13px] dark:!bg-transparent'>
            <div className='p-4 border-2 border-coal-250 dark:border-white rounded flex justify-center items-center size-[51px]'>
              <WalletIcon className='fill-coal-250 dark:fill-white scale-[1.80]' />
            </div>
            <h2 className='text-coal-250 dark:text-white !text-3xl font-semibold lg:text-4xl lg:font-bold'>Connect your wallet</h2>
          </div>
          <p className='text-coal-250 dark:text-white text-base font-normal lg:w-[85%] text-center lg:text-start'>{text || 'Connect your wallet to start a Loan Request'}</p>
        </div>
        {children}
      </div>
    </div>
  );
};

export { ConnectWallet };
